.mui-field {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px !important;
  .mui-field-label {
    font-size: 14px;
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .mui-field-value {
    flex-flow: nowrap !important;
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }
    
    .field-icon {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  .mui-field-footer-label {
    font-style: oblique;
    font-size: 14px;
    width: 100%;
    padding-bottom: unset;
  }

  .add-new-label {
    color: blue;
    margin-left: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      font-size: 18px;
    }
  }

  .file-image {
    width: 60px;
    height: 60px;
    border: 1px solid;
    cursor: pointer;
  }

  .delete-file-image {
    cursor: pointer;
    color: blue;
    margin-left: 6px;
    text-decoration: underline;
  }

  .MuiFormLabel-root {
    width: 135%;
    position: relative;
    white-space: break-spaces;
    margin: 0px 0 -18px 0;
    min-height: min-content;
  }

  margin-bottom: 16px;
}
.inline {
  align-items: center;
}

.plainField {
  .MuiInputBase-root {
    background-color: unset;
    .Mui-disabled {
      -webkit-text-fill-color :#000000;
      font-weight:400 ;
    }   
  }
}

.MuiInputBase-root.Mui-error {
    border: 1px solid #9D2727;
  }


