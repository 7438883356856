.form-group {
  margin-bottom: 0px;
  margin-top: 16px;

  .MuiTextField-root {
    label {
      margin-top: -5px;
    }
  }

  input {
    padding: 0 14px;
    height: 40px;
  }
}
