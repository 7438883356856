@import url("https://code.highcharts.com/css/highcharts.css");

:root {
  --primary-orange: #F78D2A;
  --primary-blue: #182A53;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #F78D2A;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  z-index: 1000;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.result-block-container .result-block {
  opacity: 1;
}

.loading {
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}
.loadImg{
  height: 50px;
  width: 60px;
  z-index: 1000;
  position: absolute;
  top: 13px;
  left: 12px;
}
.loaderBackdrop{
  position: fixed;
  z-index: 1000;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.userImg {
  border-radius: 100px;
  display: block;
  height: 100px;
  margin: 0 auto;
  width: 100px;
}
.loaderPosition{
  position: fixed;
  top: 45%;
  left: 47%;
}
.hero .app-logo {
  max-width: 10.5rem;
}
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}


.result-block-container .result-block {
  opacity: 1;
}
button:focus {
  outline: none;
}
p {
  margin: unset;
}
.container {
  width: 100%;
  padding: 0px 80px;
  max-width: unset;
  margin: 0px;
}

.app-main-content {
  background-color: #FBFAF8;
}

a:hover {
  text-decoration: unset;
}

.contacts-name .form-group{
  width: 47%
 }
.assessmentRequestQuestions .form-group{
  margin-top: 0
 }
 

 .toastBody {
  font-weight: 500;
  border-radius: 8px;
  color: #FFF;
}

.Toastify__toast {
  min-height: 48px !important;
  border-radius: 8px !important;
}

.Toastify__toast--success {
  background: #2C9543  !important;
}

.Toastify__toast--error {
  background: #9D2727!important;
}

.chart-tooltip{
  background-color: #D8DEE4 !important;
  fill: #D8DEE4 !important;
  box-shadow: -4px -4px 40px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}
.highcharts-tooltip-box {
  background-color: #D8DEE4 !important;
  fill: #D8DEE4 !important;
}

.highcharts-background {
  background-color: #fff !important; 
  fill: #fff !important;
}

.highcharts-container {
  font-family: 'roboto';
}

.fa-solid {
  font-weight: inherit;
  font-family: inherit;
}
